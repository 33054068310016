export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      zIndex: '1001',
      // position: 'relative',
      color: 'light',
      padding: ['2rem 1rem', '3.5rem 1rem', '3.5rem 2rem', '3.5rem 1rem']
    },

    '.containerScrolled': {
      animationFillMode: 'unset !important',
      zIndex: '1001',
      position: 'fixed',
      padding: ['2rem 1rem', '0.5rem 1rem', '0.5rem 2rem', '0.5rem 2rem'],
      backgroundColor: 'background',
      height: ['unset', 'unset'],
      color: 'text',
      '.hamburger > div': {
        // backgroundColor: '#c6a34d',
      }
    },
    '.locationSwitcher': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '.locationSwitcherV1': {
        padding: ['0.25rem', '0.25rem', '0.25rem', '0.25rem', '0.25rem'],
        '.button': {
          variant: 'buttons.secondary',
          padding: '0.5rem',
          svg: {
            marginRight: '0.25rem'
          }
        }
      },
      '.bethel': {
        display: 'none!important'
      },
      '.popUp': {
        transform: 'unset',
        width: '100%',
        '.content': {
          backgroundColor: 'background',
          padding: '2rem',
          '.locations': {
            a: {
              variant: 'buttons.secondary'
            }
          }
        }
      }
    },

    '.logo': {
      img: {
        opacity: '1',
        filter: 'brightness(0) invert(1)',
        maxHeight: ['55px', '60px']
      }
    },
    '.logoScrolled': {
      img: {
        // filter: 'brightness(0) invert(1)',
        opacity: '1',
        maxHeight: ['55px', '60px']
      }
    },

    '.smallNavMenu': {
      flexGrow: '1',
      '> div': {
        border: 'none',
        '.navItem': {
          padding: '0.5rem',
          color: 'inherit',
          a: {
            fontWeight: '300',
            opacity: '0.8',
            textAlign: 'center',
            letterSpacing: '1px',
            fontSize: ['1rem', '1rem', '1rem', '1rem']
          }
        }
      }
    },
    '.smallNavMenuLeft': {
      margin: '0rem auto 0rem 0rem',
      justifyContent: 'center'
    },
    '.smallNavMenuRight': {
      margin: '0rem 0rem 0rem auto',
      justifyContent: 'center'
    },

    '.navItem': {
      color: 'text',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      width: '100%',
      flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      a: {
        fontSize: ['1rem', '1.25rem', '.75rem', '.9rem!important']
      },
      justifyContent: 'center',
      ':hover': {
        color: 'inherit'
      }
    },
    '.the-red-door': {
      background: '#F68476',
      color: 'white!important',
      borderRadius: '4px'
    },
    '.hamburger': {
      display: ['', '', '', 'none'],
      '> div': {
        backgroundColor: 'white'
      }
    },

    '.hamburgerOpen': {
      position: 'fixed'
    },

    '.logoLocationContainer': {
      padding: 0
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      backgroundColor: 'black',
      img: {
        // filter: 'brightness(0) invert(1)',
        maxHeight: ['55px', '', '100px', '']
      }
    },
    '.navMenuOpen': {
      position: 'fixed',
      backgroundColor: 'primary',
      width: ['90%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0.75rem', '0.75rem', '1rem', '0.75rem', '0.75rem', '1rem'],
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem'],
          color: 'white'
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: 'secondary',
      paddingBottom: '1rem'
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem']
      },
      margin: '0rem',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'light'
        }
      }
    }
  },

  footer: {
    borderTop: '3px solid',
    borderColor: 'primary',
    backgroundColor: 'secondary',
    padding: ['0rem', '0rem', '0rem', '0rem'],
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },
    '.column': {
      padding: '2rem 4rem 2rem 2rem',
      backgroundColor: 'background',
      borderLeft: ['none', '', 'solid 3px'],
      borderColor: ['', '', 'primary']
    },
    '.logo': {
      filter: 'brightness(0) invert(1)',
      padding: '2rem'
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.copyright': {
      backgroundColor: 'transparent'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    },

    '.locationSwitcherV1': {
      '.logo': {
        filter: 'unset',
        padding: '1rem'
      },

      '.popUp': {
        transform: 'unset',
        width: '100%',
        '.content': {
          backgroundColor: 'background',
          padding: '2rem',
          '.locations': {
            a: {
              variant: 'buttons.secondary'
            }
          }
        }
      }
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    // borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['1.75rem', '', '2.5rem'],
    textTransform: 'uppercase',
    color: 'text',
    fontWeight: 'bold',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: '#bbbbbb',
    fontWeight: 'normal',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'display',
    fontSize: ['1rem', '1.5rem', '1.75rem', '2rem'],
    textTransform: 'normal'
  },

  text: {
    order: '3',
    fontSize: ['1.25rem', '', '1.5rem'],
    '*': {
      color: 'inherit'
    }
  },

  sideBySide1: {
    overflow: 'hidden',
    padding: ['0rem', '1rem', '', '2rem'],
    alignItems: ['', '', 'flex-start'],
    marginLeft: ['', '', '', '-4rem'],
    '.content': {
      // order: ['', '', '2'],
      width: ['', '', '45%', '35%'],
      padding: ['2rem 0.5rem 2rem 3rem', '2rem 0.5rem 2rem 3rem', '2rem 0.5rem 2rem 3rem', '1.5rem 3.5rem', '4rem'],
      position: ['', '', 'relative'],
      left: ['', '', '0%'],
      top: ['', '', '5rem'],
      // border: ['', '', 'solid 4px #c8c8c8'],
      // boxShadow: ['', '', '2px 2px 10px #202020'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      // order: '1',
      width: ['', '', '55%', '65%'],

      height: ['', '', '80vh'],
      '::before': {
        background: 'unset'
      },
      img: {
        // height: '100vh',
        // border: ['solid 6px', 'solid 6px', 'solid 10px'],
        // borderColor: ['secondary', 'secondary', 'secondary'],
        // boxShadow: '2px 2px 10px black',
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  contentBlock: {
    minHeight: ['90vh'],
    padding: ['15vh 1rem', '', '20vh 1rem'],
    margin: ['0rem', '', '0rem'],
    '.section': {
      width: ['100%', '75%'],
      textAlign: 'left',
      alignItems: 'flex-start',
      margin: '0rem 0rem 0rem 10%',
      // textShadow: '2px 2px 10px black',
      color: 'white'
    },
    '.title': {
      variant: 'customVariants.title',
      width: '100%',
      border: 'none',
      textAlign: 'left',
      color: 'light',
      textTransform: 'capitalize',
      fontSize: ['1.5rem', '2rem', '3rem', '4rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontFamily: 'display',
      borderTop: '8px solid',
      borderColor: 'primary',
      paddingTop: '1.5rem',
      color: '#cfa46b'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  contentBlock2: {
    variant: 'customVariants.contentBlock'
  },

  pageHeroSlider: {
    overflow: 'hidden',
    height: '80vh',
    '.slick-slider': {
      height: '80vh',
      '.slick-slide img': {
        height: '80vh'
      },
      '.slick-slide > div': {
        height: '80vh'
      },
      '.slick-arrow': {
        left: '1rem',
        bottom: '0rem',
        top: 'unset',
        height: '35px',
        width: '35px',
        padding: '0.25rem',
        backgroundColor: 'primary',
        color: 'white'
      },
      '.slick-prev': {},
      '.slick-next': {
        left: '4rem'
      }
    },

    '.hero_content_container': {
      marginBottom: 1,
      transformOrigin: 'center',
      transform: 'translate(50%, -50%) rotate(-90deg)',
      left: 'unset',
      top: '25%',
      right: '3.5rem',
      position: 'absolute',
      fontWeight: '300',
      fontSize: ['3rem', '', '4.5rem'],
      width: '100%',
      textTransform: 'uppercase',
      '.title': {
        color: 'white',
        textTransform: 'uppercase'
      }
    }
  },
  pageHeroSliderShout: {
    display: 'none'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    background: 'url(https://res.cloudinary.com/gonation/image/upload/v1688410658/sites/the-benjamin/border.png)',
    backgroundSize: ['100% 55%', '', '', '100% 104%'],
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: ['unset', 'unset', 'unset', 'unset'],
    height: '100vh',
    backgroundColor: 'primary',

    '.hero_content_container': {
      position: 'static',
      transform: 'unset',
      padding: ['2rem', '', '5rem 5rem 2rem'],
      maxWidth: 'unset',
      textAlign: 'center'
    },

    '.title': {
      variant: 'customVariants.title',
      textTransform: 'unset',
      color: 'light',
      fontSize: ['3rem', '4rem', '5rem', '6rem'],
      width: '100%',
      order: 2
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['2rem', '3rem', '4rem', '5rem'],
      order: 1,
      textTransform: 'lowercase',
      m: '0rem',
      textAlign: 'left',
      alignSelf: 'flex-start'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      maxWidth: '600px',
      margin: '1rem auto 1rem',
      order: 3
    },
    a: {
      order: 4
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },

    '.slick-initialized': {
      height: ['70vh', '', '', '', '100vh'],
      '.slick-slide > div': {
        overflow: 'hidden',
        position: 'relative',
        height: ['70vh', '', '', '', '100vh']
      },
      '.slick-slide img': {
        height: ['70vh', '', '', '', '100vh'],
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        objectFit: 'cover',
        transform: 'translate(-50%, -50%)'
      },
      '.slick-dots': {
        bottom: '25px'
      },
      '.slick-prev:before, .slick-next:before': {
        color: 'white',
        fontSize: '25px'
      },

      '.slick-dots li button:before': {
        color: 'white',
        fontSize: '10px'
      },
      '.slick-next': {
        right: '5px',
        zIndex: '500'
      },
      '.slick-prev': {
        left: '0px',
        zIndex: '500'
      },

      'svg.slick-arrow': {
        width: '20px',
        color: 'primary'
      }
    }
  },
  homepageHeroShout: {
    display: 'none !important'
  },

  homepageCenterBlock: {
    variant: 'customVariants.contentBlock'
  },

  homepageShout: {
    '.containerPopUp': {
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
      backgroundColor: '#fff8f8',
      '.title': {
        color: 'primary'
      },
      '.text': {
        fontFamily: 'heading'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    background:
      'url(https://res.cloudinary.com/gonation/image/upload/o_70/v1688416052/sites/the-benjamin/BEN_Script-B_Navy.png)',
    backgroundSize: '6vw',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30% 95%'
  },

  homepageMenu: {
    variant: 'customVariants.contentBlock'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    background:
      'url(https://res.cloudinary.com/gonation/image/upload/o_70/v1688415957/sites/the-benjamin/BEN_Keyhole_Red.png)',
    backgroundSize: '6vw',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '70% 90%'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    background:
      'url(https://res.cloudinary.com/gonation/image/upload/o_70/v1688415712/sites/the-benjamin/BEN_Key-V_Red.png)',
    backgroundSize: '6vw',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '30% 90%'
  },

  homepageSectionBoxes: {
    padding: '0rem 1.5rem 1.5rem',
    margin: ['', ' 3rem 0rem'],
    zIndex: '10',
    '.contentBoxes': {
      width: '100%'
    },
    '.box': {
      backgroundColor: 'backgroundSecondary',
      margin: ['0.5rem', '1rem', '0.5rem', '1rem'],
      // boxShadow: '2px 4px 10px black',
      padding: ['0.5rem', '', '', '0.5rem', '1rem'],
      transition: 'all ease-in-out 0.8s',
      width: ['calc(100% - 1rem)', 'calc(100% - 2rem)', 'calc(50% - 1rem)', 'calc(33.3% - 2rem)'],
      '.image': {
        height: '100%',
        minHeight: '300px',
        height: '300px',
        objectFit: 'cover',
        marginBottom: '1.5rem'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.5rem'],
        color: 'primary',
        order: 'unset'
      },
      ':hover': {
        backgroundColor: '#efeee6'
        // color: 'white',
      }
    }
  },

  homepageContact: {
    variant: 'customVariants.contentBlock'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  aboutPageBlock: {
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      fontSize: ['2rem', '2.5rem', '3.5rem', ' 4rem'],
      padding: '2rem 1rem'
    },
    '.section': {
      margin: '0rem 0rem',
      maxWidth: '1200px',
      textAlign: 'left',
      alignItems: 'flex-start'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  giftCardIntro: {
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      fontSize: ['2rem', '2.5rem', '3.5rem', ' 4rem'],
      padding: '2rem 1rem'
    },
    '.section': {
      margin: '0rem 0rem',
      maxWidth: '1200px',
      textAlign: 'left',
      alignItems: 'flex-start'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    '.ctaButton': {
      backgroundColor: 'black',
      transition: 'all ease-in-out 0.5s',
      ':hover': {
        backgroundColor: 'transparent',
        color: 'text',
        borderColor: 'text'
      }
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menuCtas: {
    '.contentBoxes': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.ctaLink': {
      margin: 'auto'
    }
  },

  menu: {
    margin: '0rem 0rem',
    maxWidth: 'unset',
    padding: ['2rem 1rem', '', '3rem 2rem'],
    // backgroundImage:
    //   'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
    // backgroundColor: '#fff8f8',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    fontFamily: 'heading',
    '.menuItemName, .menuItemPrice, .menuSectionTitle': {
      fontFamily: 'heading'
    },
    '.backToMenuBtn': {
      variant: 'buttons.secondary',
      margin: '0rem auto 2rem 0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', '', 'calc(50% - 1rem)'],
      borderRadius: '0px',
      position: 'relative'
    },
    '.allInContainerWrapper ': {
      maxWidth: '1200px',
      margin: '0rem auto'
    },
    '.cellImageContainer': {
      overflow: 'hidden'
    },
    '.cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s',
      ':hover': {
        transform: 'scale(1.1) translate(-50%, -50%)',
        transformOrigin: 'center center'
      }
    },
    '.menuCell': {
      borderRadius: '0px',
      '.imageFill': {
        paddingBottom: '60%'
      }
    },

    '.cellName': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'light',
      margin: '0rem',
      fontSize: ['1.5rem', '', '1.75rem', '2.5rem']
    },

    '.tabsRow': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      borderBottom: 'solid 2px black',
      borderTop: 'solid 2px black',
      marginBottom: ['2.5', '', '3.5rem'],
      padding: '1rem 1.5rem 1.5rem',
      '::before': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
        content: "'The Menu'",
        margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
        width: ['100%', '', '', 'fit-content'],
        letterSpacing: '0px',
        textTransform: 'capitalize',
        padding: '1rem 0rem',
        fontFamily: 'heading',
        color: 'primary',
        textTransform: 'uppercase'
      },
      '.menuTab': {
        fontSize: '0.9rem',
        border: 'none',
        textTransform: 'capitalize',
        padding: '0.25rem',
        letterSpacing: '-1px',
        fontFamily: 'heading',
        height: 'fit-content',
        fontWeight: '300',
        backgroundColor: 'transparent',
        margin: 'auto 0.25rem',
        ':hover': {
          color: 'primary'
        }
      },
      '.menuTabActive': {
        color: 'text',
        borderBottom: '1px solid black'
      }
    },

    '.menuContainer': {
      paddingBottom: '0rem'
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      fontSize: ['1.5rem', '1.75rem', '2.25rem', '2.5rem'],
      width: '100%',
      color: 'primary',
      letterSpacing: '2px',
      textTransform: 'uppercase'
      // textTransform: 'uppercase',
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      opacity: '0.75',
      fontSize: '0.9rem',
      color: 'text',
      marginBottom: 'rem'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      margin: '0rem 0rem 1rem',
      padding: ['0rem', '', '0rem 1.5rem 0rem 0rem']
    },

    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      color: 'text',
      fontSize: ['1rem', '1rem', '1.1rem', '1.2rem']
    },
    '.menuItemDescription': {
      color: 'text',
      textTransform: 'lowercase'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '::before': {
      display: 'flex',
      content: "''",
      height: '183px',
      width: '100%',
      backgroundColor: 'primary'
    },
    '.albumsContainer': {
      flexDirection: 'row-reverse'
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '5rem 1rem',
    order: '4',
    backgroundColor: '#fffdf3',
    border: ['solid 10px white', 'solid 10px white', 'solid 15px white'],
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'primary',
      marginBottom: '2rem',
      variant: 'customVariants.title',
      color: 'white'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem',
      color: 'white'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white',
        '::placeholder': {
          color: 'white'
        }
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white',
        '::placeholder': {
          color: 'white'
        }
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'primary',
    color: 'light',
    '.content_container': {
      border: 'solid 10px',
      borderColor: 'secondary'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      color: 'white',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },
    '.dayofWeekText': {
      margin: '0 auto 0 0'
    },

    '.hours-section': {
      '.textContent': {
        '.title': {
          fontSize: '0rem',
          '::before': {
            fontSize: '2rem',
            content: "'Our Kitchen Hours'"
          }
        }
      }
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Reservations  ====
  // ? ========================

  reservations: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 1rem'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: 'secondary',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      color: 'light',
      borderBottom: '2px solid #cababa'
    },
    '.subtitle': {
      color: 'light'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},

  // ? ==========================
  // ? ====  Private Dining  ====
  // ? ==========================

  privateDiningIntro: {
    background: 'unset'
  }
}
