export default {
  heading: 'Ariata, serif',
  body: 'Mr Eaves San, serif',
  monospace: 'Menlo, monospace',
  display: 'Pinyon Script, cursive',
  googleFonts: [
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900',
    'Caveat:400,500,600,700',
    'Poppins:100,200,300,400,500,600,700,800,900',
    'Pinyon Script'
  ],
  customFamilies: ['Din', 'Ariata', 'Mr Eaves San'],
  customUrls: [
    'https://www.gonation.biz/fonts/din/din.css',
    'https://www.gonation.biz/fonts/ariata/ariata.css',
    'https://www.gonation.biz/fonts/mr-eaves-san/mr-eaves-san.css'
  ]
}
