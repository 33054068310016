export default {
  text: 'black',
  primary: '#313847',
  secondary: '#1c3c76',
  highlight: '#F04F3F',
  background: '#fbf8f0',
  backgroundSecondary: '#fbf7ef',
  light: '#fdfdfd',
  dark: '#202020'
}
